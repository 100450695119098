const firebaseConfig = {
	apiKey: "AIzaSyANWhu62SkZ_yqR7yrDk8lmNIr6b-Eaiiw",
	authDomain: "survivors-labs.firebaseapp.com",
	projectId: "survivors-labs",
	storageBucket: "survivors-labs.appspot.com",
	messagingSenderId: "241604894427",
	appId: "1:241604894427:web:574536d70ed2ec7add76fe",
	measurementId: "G-KL9WXYWQM8",
};

export { firebaseConfig };
