const PRIMARY = "#febc42";
const SECONDARY = "#1b1f20";
const THIRD = "#967434";
const TEXT = "#ffefc6";

export { 
    PRIMARY,
    SECONDARY,
    THIRD,
    TEXT
}